import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import MetaTags from "react-meta-tags";
import jwt_decode from 'jwt-decode';
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Collapse,
    Label,
    Form,
    Input,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from '../../api/axios';
import { ParkingLayoutModal } from './ModalForm/ModalForm';

const ITEMS_PER_PAGE = 100;
const BASE_URL = '/parking_new/property';
const INITIAL_FILTER_OPTIONS = {
    parking_type: [],
    floor: [],
    city: [],
    status: []
};
const INITIAL_FILTER = { parking_type: '', floor: '', city: '', status: '' };
const INITIAL_STATS = { total: 0, activeProperties: 0 };

const LayoutSetup = () => {
    const [properties, setProperties] = useState([]);
    const currentPage = 1;
    const [filterData, setFilterData] = useState(INITIAL_FILTER);
    const [filterOptions, setFilterOptions] = useState(INITIAL_FILTER_OPTIONS);
    const [propertyStats, setPropertyStats] = useState(INITIAL_STATS);
    const [isFilterOpen, setIsFilterOpen] = useState(true);
    const [modalState, setModalState] = useState({ isOpen: false, property: null });

    const dispatch = useDispatch();

    const getUserFromToken = () => {
        const token = window.localStorage.getItem('accessToken');
        if (!token) return null;
        try {
            return jwt_decode(token);
        } catch (error) {
            console.error('Error decoding token:', error);
            return null;
        }
    };

    const fetchProperties = async () => {
        const user = getUserFromToken();
        if (!user) return;

        try {
            dispatch({ type: "SET_LOADING", payload: true });

            const response = await axios.get(BASE_URL, {
                params: {
                    page: currentPage,
                    limit: ITEMS_PER_PAGE,
                    role: user.role,
                    domain: user.domain,
                    managerID: user.role === "manager" ? user.id : "",
                    filter: filterData,
                },
            });

            const { results, filters: options } = response.data;
            setProperties(results.properties || []);
            setPropertyStats({
                total: results.totalProperties || 0,            // Total number of properties
                activeProperties: results.totalActiveProperties || 0,  // Total number of active properties
                totalPages: results.totalPages || 0,            // Total number of pages for pagination
                currentPage: results.currentPage || 1,          // Current page number
            });

            if (options) {
                setFilterOptions({
                    parking_type: options.parkingTypes || [],  // parkingTypes from response
                    floor: options.floors || [],                // floors from response
                    city: options.cities || [],                // cities from response
                    status: options.statuses || [],            // statuses from response
                });
            }

        } catch (error) {
            console.error("Error fetching properties:", error);
        } finally {
            dispatch({ type: "SET_LOADING", payload: false });
        }
    };

    useEffect(() => {
        fetchProperties();
    }, [currentPage]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilterData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFilterSubmit = (e) => {
        e.preventDefault();
        fetchProperties();
    };

    const handlePropertyAction = (property) => {
        setModalState({ isOpen: true, property });
    };

    const handleParkingLayoutSubmit = async (values, propertyId, building_name) => {
        try {
            const { layouts } = values;
            await axios.post(`/parking_new/parking-layout`, { property_id: propertyId, building_name, values, layouts });
            fetchProperties();
        } catch (error) {
            console.error('Error saving parking layout:', error);
        }
    };

    const closeModal = () => setModalState({ isOpen: false, property: null });

    const FilterSection = () => (
        <Collapse isOpen={isFilterOpen}>
            <Card>
                <CardBody>
                    <Form onSubmit={handleFilterSubmit}>
                        <div className="row align-items-center">
                            {['parking_type', 'floor', 'city', 'status'].map((field) => (
                                <div key={field} className="col-md-3">
                                    <Label className="form-label">
                                        {field.replace('_', ' ').toUpperCase()}
                                    </Label>
                                    <Input
                                        type="select"
                                        name={field}  // Use the field in the format matching INITIAL_FILTER
                                        value={filterData[field]}  // Bind the field to filterData
                                        onChange={handleFilterChange}
                                    >
                                        <option value="">Select One</option>
                                        {filterOptions[field]?.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </Input>
                                </div>
                            ))}
                            <div className="col-md-12 text-end">
                                <button type="submit" className="btn btn-primary">
                                    Search
                                </button>
                            </div>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </Collapse>
    );

    const StatisticCard = ({ title, value }) => (
        <h5 className="col-2 text-start card-title">
            {title}
            <span className="text-muted fw-normal ms-2">({value})</span>
        </h5>
    );

    const PropertiesTable = () => (
        <Table className="table-striped table-bordered mb-0">
            <thead>
                <tr>
                    <th className={'col-1'}>#</th>
                    <th className={'col-2'}>Title</th>
                    <th className={'col-2'}>Buiding</th>
                    <th className={'col-1'}>Location</th>
                    <th className={'col-1'}>Status</th>
                    <th className={'col-1'}>Action</th>
                    <th className={'col-3'}>Layouts Available</th>
                </tr>
            </thead>
            <tbody>
                {properties.length > 0 ? (
                    properties.map((property, index) => (
                        <tr key={property.id || index}>
                            <td className={'col-1'}>{index + 1}</td>
                            <td className={'col-2'}>{property.title}</td>
                            <td className={'col-2'}>{property.building_name || 'N/A'}</td>
                            <td className={'col-1'}>{property.location}</td>
                            <td className={'col-1'}>{property.status}</td>
                            <td className={'col-1'}>
                                <button
                                    className="btn btn-sm btn-primary"
                                    onClick={() => handlePropertyAction(property)}
                                >
                                    <FeatherIcon icon="edit" size={16} />
                                </button>
                            </td>
                            <td className="col-3">
                                {property.layouts && property.layouts.length > 0 ? (
                                    property.layouts.map((layout, index) => (
                                        <div key={index}>
                                            <span className='text-black'>Parking Type:</span> {layout.parking_type} <br />
                                            <span className='text-black'>Floor:</span> {layout.floor} <br/>
                                            <span className='text-black'>Slots:</span> {layout.slots}
                                        </div>
                                    ))
                                ) : (
                                    <span>No layouts available</span>
                                )}
                            </td>

                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="5" className="text-center">
                            No properties found
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Parking Layout Setup | Rentdigicare</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Parking Layout Setup" />
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader>
                                    <Row className='mb-2'>
                                        <StatisticCard title="Total Properties" value={propertyStats.total} />
                                        <StatisticCard title="Active Properties" value={propertyStats.activeProperties} />
                                        <button
                                            className={`col-2 btn btn-${isFilterOpen ? 'secondary' : 'primary'}`}
                                            onClick={() => setIsFilterOpen(!isFilterOpen)}
                                        >
                                            {isFilterOpen ? 'Collapse Filter' : 'Expand Filter'}
                                        </button>
                                    </Row>
                                    <FilterSection />
                                </CardHeader>
                                <CardBody>
                                    <PropertiesTable />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            <ParkingLayoutModal
                isOpen={modalState.isOpen}
                toggle={closeModal}
                property={modalState.property}
                onSubmit={handleParkingLayoutSubmit}
            />
        </React.Fragment>
    );
};

export { LayoutSetup };
