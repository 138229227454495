import { useState, useEffect, useRef } from "react";
import "./style.css";
import { useParams, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import MetaTags from "react-meta-tags";
import { AvField, AvForm } from "availity-reactstrap-validation";
import ThankYou from "../../assets/images/thnx.gif";

import {
  Button,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import jwt_decode from "jwt-decode";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../assets/images/rdig_logo_cal.jpg";
import logoIc from "../../assets/images/logo-sm-icn.png";
import axios from "axios";
import { SERVER_URL } from "../ServerLink";
import Select from "react-select";
import favicon from "../../assets/favicon.ico";
import momenttz from "moment-timezone";

function Calendarurl() {
  // const [value, onChange] = useState(new Date());
  const location = useLocation();
  const [dateFromAPi] = useState([]);
  const [date, setDate] = useState(new Date());
  const [data, setdata] = useState([]);
  const [slots, setslots] = useState([]);
  const [managerAvailability, setmanagerAvailability] = useState("");
  // const onChange=()=>{
  //   setDate(date)
  // }
  const decode = window.localStorage.getItem("accessToken")
    ? jwt_decode(window.localStorage.getItem("accessToken"))
    : "";
  const [managerid, setmanagerid] = useState("");
  const [Role, setrole] = useState("");
  const [companyDomain, setcompanyDomain] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [phone1, setPhone1] = useState("");
  const [sdate, setSdate] = useState("");
  const [time, setTime] = useState("");
  const [availabilityObjectIndex, setAvailabilityObjectIndex] = useState(null);
  const [description, setdescription] = useState("");
  const [day, setday] = useState("");
  const [properties, setProperties] = useState([]);
  const [property, setproperty] = useState("");
  const [layOut, setlayOut] = useState("");
  const [category, setCategory] = useState([]);
  const [selectedDay, setSelectedDay] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [daysAvailability, setDayAvailability] = useState([]);
  const [utcDaysAvailability, setUtcDaysAvailability] = useState([]);
  const [splitTimes, setSplitTimes] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [reasonType, setReasonType] = useState([]);

  const [filteredProperties, setFilteredProperties] = useState([]);
  const [reason, setReason] = useState("");
  const formRef = useRef();
  const [propertyName, setPropertyName] = useState("");
  const [contact, setContact] = useState("");
  const [logoo, setlogoo] = useState("");
  const [companyName, setcompanyName] = useState("");
  const GET_CUSTOMER_TICKETS = "/calender/customer_tickets";
  const [queryPropertyName, setQueryPropertyName] = useState("");
  const GET_EVENTS_OF_DATE = "/user_appointment/get-todays-event";
  const moment = require("moment");

  let addMoment;

  let splitedTime = [];

  let newSlot = false;
  const { id } = useParams();
  useEffect(() => {
    getManagerid(id);
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const propertyName = searchParams.get("propertyName");
    setQueryPropertyName(propertyName);
  }, [location]);

  useEffect(() => {
    if (queryPropertyName) {
      // If propertyName is received, filter the properties based on it
      const filteredProperty = properties.find(
        (item) => item.title === queryPropertyName
      );
      setFilteredProperties(filteredProperty ? [filteredProperty] : []);
    } else {
      setFilteredProperties(properties);
    }
  }, [propertyName, properties]);

  const getManagerid = async (managerID) => {
    let res = await fetch(
      SERVER_URL + `/user_appointment/get-id/?id=${managerID}`
    );
    let data = await res.json();
    console.log(data, "125478");
    let layout = data?.manager?.proprtyList.map((item) => {
      return item.categoryList;
    });
    setlogoo(data?.manager?.company?.logo);
    setcompanyName(data?.manager?.company?.name);
    setmanagerid(data?.manager?._id);
    setContact(data?.manager?.phone);
    setrole(data?.manager?.role);
    setcompanyDomain(data?.manager?.companyAssigned);
    setProperties(data?.manager.proprtyList);
    availabilityData(data?.manager?._id);
    getReasonTypes(
      data?.manager?.role === "manager"
        ? data?.manager?.companyID
        : data?.manager?._id
    );
  };

  // const convertToLocalTime = (utcTime) => {
  //   const utcDate = new Date(`1970-01-01T${utcTime}`);

  //   const localTime = utcDate.toLocaleTimeString(undefined, {
  //     hour: "numeric",
  //     minute: "numeric",
  //     hour12: true,
  //     timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  //   });

  //   console.log(localTime);
  //   return localTime;
  // };

  const convertToLocalTime = (utcTime) => {
    const utcDateTime = momenttz.utc(
      `1970/01/01 ${utcTime}`,
      "YYYY/MM/DD HH:mm:ss"
    );
    const localDateTime = utcDateTime.clone().tz(moment.tz.guess());
    return localDateTime.format("h:mm A").toUpperCase().trim();
  };

  function convertToValidDate(dateString) {
    const [datePart, timePart] = dateString.split(" ");
  
    if (!timePart) return null; // Check if timePart exists to avoid error
  
    const [time, modifier] = timePart.split(" "); // Split time and AM/PM
  
    let [hours, minutes] = time.split(":").map(Number);
  
    if (isNaN(hours) || isNaN(minutes)) {
      return null; // Return null for invalid time
    }
  
    // Adjust hours based on AM/PM
    if (modifier === "PM" && hours < 12) {
      hours += 12;
    } else if (modifier === "AM" && hours === 12) {
      hours = 0;
    }
  
    // Reconstruct the datetime string in ISO format (YYYY-MM-DDTHH:MM:SS)
    const formattedDate = `${datePart}T${hours
      .toString()
      .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:00`;
  
    const dateTimeObject = new Date(formattedDate);
  
    if (isNaN(dateTimeObject.getTime())) {
      return null; // Return null for invalid date
    }
  
    return dateTimeObject;
  }
  
  /* Logic Start */
  const [startTime, endTime] = time.split(" - ");
  console.log("startTime:", startTime, "endTime:", endTime);
  
  const [utcStartTime, utcEndTime] = [startTime, endTime].map((time) => {
    console.log("Current time being processed:", time);
  
    const dateTimeString = `${sdate} ${time}`;
    console.log("dateTimeString:", dateTimeString);
  
    if (dateTimeString.trim() === "") {
      return undefined;
    }
  
    const dateTimeObject = convertToValidDate(dateTimeString);
    console.log("Converted Date Object:", dateTimeObject);
  
    // Handle the case where dateTimeObject is null (invalid date)
    if (!dateTimeObject) {
      console.log("Invalid date, returning undefined.");
      return undefined;
    }
  
    const utcString = dateTimeObject.toISOString();
    console.log("utcString:", utcString);
  
    const timeInMilliseconds = dateTimeObject.getTime();
    console.log("timeInMilliseconds:", timeInMilliseconds);
  
    return { utcString, timeInMilliseconds };
  });
  
  console.log("utcStartTime:", utcStartTime, "utcEndTime:", utcEndTime);
  

  /* Logic End */

  const getReasonTypes = async (val) => {
    try {
      const res = await fetch(
        SERVER_URL + `/calender/reason/get?companyID=${val}`
      );
      let data = await res.json();
      if (data.results.reasons) {
        const temp = data.results.reasons;
        setReasonType(temp);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const setSlots = (date) => {
    const localDate = new Date(date);
    const utcDate = new Date(
      localDate.getTime() + localDate.getTimezoneOffset() * 60000
    );
    setCalenderSlots(utcDate);
  };

  const isDateDisabled = (date, daysAvailability) => {
    const selectedDate = moment(date);

    // Get the day of the week in short format (e.g., 'Sun', 'Mon', etc.)
    const dayOfWeek = selectedDate.format("ddd");

    console.log(dayOfWeek);

    const selectedDayAvailability = daysAvailability.find(
      (dayObj) => dayObj.day === dayOfWeek && !dayObj.available
    );

    return !!selectedDayAvailability;
  };

  const availabilityData = async (id) => {
    let res = await fetch(
      SERVER_URL + `/calender/get-availability/?manager_id=${id}`
    );
    let data = await res.json();
    if (data?.ManagerAvailability != null) {
      const convertedAvailability =
        data?.ManagerAvailability?.daysOfWeekAvailability.map((day) => ({
          ...day,
          slots: day.slots.map((slot) => ({
            startTime: convertToLocalTime(slot.startTime),
            endTime: convertToLocalTime(slot.endTime),
          })),
        }));

      setDayAvailability(convertedAvailability);
      setUtcDaysAvailability(data?.ManagerAvailability?.daysOfWeekAvailability);
    } else {
      setDayAvailability([]);
    }
  };

  const setCalenderSlots = async (selectedDate) => {
    console.log("Selected Slot:", selectedDate);
    let dayIndex = selectedDate.getDay();
    let bodyData = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ date: selectedDate, manager_id: managerid }),
    };
    const res = await fetch(
      SERVER_URL + `/user_appointment/get-todays-event`,
      bodyData
    );
    let data = await res.json();
    // console.log("response:", data)

    let bookedEvents = data?.BookedEvents.map((obj) => {
      return obj.startTime;
    });
    // let bookedEvents = [];
    console.log(bookedEvents, "bookedEvents");
    let daysAvail = daysAvailability;

    let availability = daysAvail.filter((day, i) => {
      if (dayIndex == 7) {
        dayIndex = 0;
      }

      if (dayIndex == i) {
        return true;
      } else {
        return false;
      }
    });
    let dayAvailability = availability[0];
    setday(dayAvailability.day);
    // console.log(day, "dayAvailability");
    const timeRecurresive = (slot, index) => {
      return new Promise(function (myResolve, myReject) {
        const repeatFunc = (slot, index) => {
          calculateTime(
            bookedEvents,
            slot.startTime.replace(/am|pm/i, (match) => match.toUpperCase()),
            slot.endTime.replace(/am|pm/i, (match) => match.toUpperCase()),
            index
          ).then(() => {
            if (
              addMoment !==
              slot.endTime.replace(/am|pm/i, (match) => match.toUpperCase())
            ) {
              repeatFunc(slot, index);
            } else {
              myResolve();
            }
          });
        };

        repeatFunc(slot, index);
      });
    };

    splitedTime = [];

    const NextSlots = (m) => {
      timeRecurresive(dayAvailability.slots[m], m).then(() => {
        m++;
        if (m < dayAvailability.slots.length) {
          newSlot = true;
          NextSlots(m);
        } else {
          setSplitTimes(splitedTime);
        }
      });
    };

    newSlot = true;

    if (dayAvailability.available) {
      NextSlots(0);
      setmanagerAvailability("");
    } else {
      setmanagerAvailability("Manager is unAvailable on that date");
      console.log("Manager is unAvailable on that date");
    }

    // timeRecurresive()
  };

  const calculateTime = async (BookedEvents, startTime, endTime, index) => {
    return new Promise(function (resolve, reject) {
      if (!newSlot) {
        let oldMoment = addMoment;
        addMoment = moment(addMoment, ["h:mm A"]).add(15, "m").format("LT");
        let slot = `${oldMoment} - ${addMoment}`;

        if (!BookedEvents.includes(oldMoment)) {
          splitedTime.push({ slot, index });
          resolve(index); // Pass the index when resolving the promise
        } else {
          resolve(); // Resolve without the index if the slot is booked
        }
      } else {
        addMoment = moment(startTime, ["h:mm A"]).add(15, "m").format("LT");
        let slot = `${startTime} - ${addMoment}`;
        if (!BookedEvents.includes(startTime)) {
          splitedTime.push({ slot, index });
          resolve(index); // Pass the index when resolving the promise
        } else {
          resolve(); // Resolve without the index if the slot is booked
        }
        newSlot = false;
      }
    });
  };

  console.log(utcStartTime?.utcString , utcEndTime?.utcString)

  const onHandleSubmit = async () => {
    let bodyData = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...(layOut !== "" && { layoutId: layOut }),
        manager_id: managerid,
        role: Role,
        companyDomain: companyDomain,
        name: name,
        phone: phone,
        phone1: phone1,
        email: email,
        description: description,
        date: sdate,
        StartTime: utcStartTime.utcString,
        endTime: utcEndTime.utcString,
        day: day,
        reasonId: reason,
        propertyId: property,
        timeSelected: time,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        Sdate: sdate,
        // available: isAvailable,
      }),
    };
    const foundProperty = properties.find((item) => item._id === property);
    const desiredTitle = foundProperty ? foundProperty.title : null;
    setPropertyName(desiredTitle);
    console.log(bodyData, "bodyData");
    try {
      let response = await fetch(
      SERVER_URL + `/user_appointment/add_slot`,
      bodyData
      );
      let data = await response.json();

      if (data.status == 200) {
        toast("Your Appointment is booked!");
        const applicantStepForm = document.getElementById("bookAppoimentForm");
        const thanks = document.getElementById("thanks");
        applicantStepForm.style.display = "none";
        thanks.style.display = "block";
        setTimeout(function () {
          window.location.href = companyDomain; // Replace "otherpage.html" with the URL of the other page you want to navigate to
        }, 10000);
        setSplitTimes([]);
        formRef.current.reset();
      } else {
        console.log("Message:", data.message);
        toast(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const layoutList =
    properties?.filter((v) => v?._id === property)[0]?.categoryList || [];

  //view=== "month" && dateFromAPi.includes(date)?date.getDate():null
  return (
    <>
      <MetaTags>
        <title> Rentdigicare | Book Appointment</title>
        <link rel="icon" href={favicon} />
      </MetaTags>
      <div className=" appointmentForm-card ">
        <div className="row" style={{ minHeight: "80vh", minWidth: "8vh" }}>
          <ToastContainer />
          <div className="col-md-6 mx-auto">
            <div
              id="bookAppoimentForm"
              className="calendarForm-form px-4 pt-3 pb-4 opacity-transparent bg-white"
            >
              <div className="logo-container">
                <div className="rdcarelogo-container">
                  <img
                    className="parteneredwithrdcare-logo"
                    alt="Partnered with RD Care"
                    src="/images/rdcarelogo.png"
                  />
                </div>
              </div>
              <div className="header text-center">
                <a className="logo logo-dark">
                  <span className="">
                    {logoo ? (
                      <img src={logoo} alt="" height="100" />
                    ) : (
                      companyName
                    )}
                  </span>
                </a>
              </div>

              <div className="text-center mb-3">
                <h2>Book Appointment</h2>
              </div>
              <AvForm
                className="w-100"
                ref={formRef}
                onValidSubmit={(e) => {
                  e.preventDefault();
                  onHandleSubmit();
                }}
              >
                {/* {managerAvailability} */}
                {/* {addEventError && <p style={{ color: "red" }}>{addEventError}</p>} */}
                <Row className="d-flex flex-wrap" form>
                  <Col className="col-12 mb-2">
                    <AvField
                      name="name"
                      label="Name *"
                      placeholder="Enter name"
                      type="text"
                      errorMessage="Please Enter the name"
                      validate={{
                        required: { value: true },
                      }}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </Col>

                  <Col className="col-12 mb-2">
                    <AvField
                      name="email"
                      label="Email *"
                      placeholder="Enter email"
                      type="email"
                      errorMessage="Please Enter the email"
                      validate={{
                        required: { value: true },
                      }}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Col>

                  <Col className="col-6 mb-3 " style={{ paddingRight: "7px" }}>
                    <AvField
                      name="phone"
                      label="Phone *"
                      type="number"
                      placeholder="Enter phone number"
                      errorMessage="Please Enter the phone number"
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: /^\d{10,15}$/, // Regular expression to allow only digits and limit the length to between 10 and 15.
                          errorMessage:
                            "Phone number must be between 10 and 15 digits",
                        },
                      }}
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    />
                  </Col>
                  <Col className="col-6 mb-3 " style={{ paddingRight: "7px" }}>
                    <AvField
                      name="Other phone"
                      label="Other Phone"
                      type="number"
                      placeholder="Enter phone number"
                      // errorMessage="Please Enter the phone number"
                      // validate={{
                      //   required: { value: true },
                      // }}
                      validate={{
                        pattern: {
                          value: /^\d{10,15}$/, // Regular expression to allow only digits and limit the length to between 10 and 15.
                          errorMessage:
                            "Phone number must be between 10 and 15 digits",
                        },
                      }}
                      onChange={(e) => {
                        setPhone1(e.target.value);
                      }}
                    />
                  </Col>
                  <Col className="col-6 mb-3">
                    <AvField
                      name="Reason Type"
                      label="Reason Type *"
                      type="select"
                      errorMessage="Please select reason type"
                      validate={{
                        required: { value: true },
                      }}
                      onChange={(e) => {
                        setReason(e.target.value);
                      }}
                    >
                      <option>Select Reason</option>
                      {reasonType.length > 0 ? (
                        <>
                          {reasonType?.map((item) => {
                            return (
                              <option value={item._id}>
                                {item.reasonType}
                              </option>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <option>{"Not available"}</option>
                        </>
                      )}
                    </AvField>
                  </Col>
                  <Col className="col-12 mb-2">
                    <AvField
                      name="Comment"
                      label="Comment"
                      type="textarea"
                      placeholder="Enter comment"
                      // errorMessage="Please Enter the comment"
                      // validate={{
                      //   required: { value: true },
                      // }}
                      onChange={(e) => {
                        setdescription(e.target.value);
                      }}
                    />
                  </Col>
                  <Col className="col-6 mb-3 " style={{ paddingRight: "7px" }}>
                    <AvField
                      type="select"
                      name="event_Property"
                      label="Choose Property *"
                      validate={{
                        required: { value: true },
                      }}
                      errorMessage="Please select a property"
                      onChange={(e) => {
                        setproperty(e.target.value);
                      }}
                    >
                      <option>Select Property</option>
                      {filteredProperties.length > 0 ? (
                        <>
                          {filteredProperties?.map((item) => {
                            return (
                              <option value={item._id}>{item.title}</option>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <option>{"Not available"}</option>
                        </>
                      )}
                    </AvField>
                  </Col>

                  <Col className="col-6 mb-3 " style={{ paddingLeft: "7px" }}>
                    <AvField
                      type="select"
                      name="event_Category"
                      label="Choose Layout"
                      // validate={{
                      //   required: { value: true },s
                      // }}
                      onChange={(e) => {
                        setlayOut(e.target.value);
                      }}
                    >
                      <option value={""}>Select Layout</option>
                      {layoutList.length > 0 ? (
                        <>
                          {layoutList?.map((item) => {
                            return (
                              <option value={item._id}>
                                {item.layoutName}
                              </option>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <option>{"Not available"}</option>
                        </>
                      )}
                    </AvField>
                  </Col>

                  <Col className="col-6 mb-2" style={{ paddingRight: "7px" }}>
                    <AvField
                      name="event_date"
                      label="Appointment Date *"
                      type="date"
                      errorMessage="Please select appointment date"
                      min={new Date().toJSON().split("T")[0]}
                      validate={{
                        required: { value: true },
                        customValidation: (value, ctx) => {
                          if (
                            value &&
                            isDateDisabled(value, daysAvailability)
                          ) {
                            return "Selected date is not available for appointment";
                          }
                          return true;
                        },
                        // dateRange: {}
                      }}
                      onChange={(e) => {
                        setSlots(e.target.value);
                        setSdate(e.target.value);
                      }}
                      // value={
                      //     event.event_date
                      //         ? event.event_date
                      //         : ""
                      // }
                    />
                  </Col>

                  <Col className="col-6 mb-3 " style={{ paddingLeft: "7px" }}>
                    <AvField
                      type="select"
                      name="event_slot"
                      label="Choose Slot *"
                      validate={{
                        required: { value: true },
                      }}
                      errorMessage="Please select event slot"
                      onChange={(e) => {
                        const selectedOption =
                          e.target.options[e.target.selectedIndex];
                        const selectedSlotIndex =
                          selectedOption.getAttribute("data-index");
                        console.log("Index:", selectedSlotIndex);
                        setAvailabilityObjectIndex(selectedSlotIndex);
                        setTime(e.target.value);
                      }}
                    >
                      {" "}
                      <option>Select Slot</option>
                      {managerAvailability === "" ? (
                        <>
                          {splitTimes?.map((slot) => {
                            return (
                              <option value={slot.slot} data-index={slot.index}>
                                {slot.slot}
                              </option>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <option>{"No Slots Available"}</option>
                        </>
                      )}
                    </AvField>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary save-event me-2"
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn-light me-2"
                        onClick={() => window.location.replace(companyDomain)}
                      >
                        Close
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </div>
            <div id="thanks" className="text-center thanks white-bg">
              <img src={ThankYou} />
              <div className={`thankyou-title`}>
                <span>Thank You !</span>
              </div>
              <h5>
                Your Appointment request has been sent to the property manager
                for
                {propertyName ? " " + propertyName : ""}. If you need to speak
                to them, you can contact with the property manager at {contact}.
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Calendarurl;